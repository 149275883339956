import { Context } from '@nuxt/types'

import axios from 'axios'
import { withoutTrailingSlash } from 'ufo'

import { useUserStore } from '@/store'
import { generateBreadcrumbUrls, generateBreadcrumbsData } from '@/common/utils/route'

import {
  NavigationLink,
  ContentPage,
  News,
  Protocol,
  ProteinDomain,
  PathwayDiagram,
  ContentVideo,
} from '~/modules/contentstack/types'

import { BreadcrumbsData } from '~/types/breadcrumbs'

enum CMSPageComponentTypes {
  ContentPage = 'ContentPage',
  NewsPage = 'NewsPage',
  PathwayDiagramPage = 'PathwayDiagramPage',
  ProteinDomainPage = 'ProteinDomainPage',
  ProtocolPage = 'ProtocolPage',
  VideoPage = 'VideoPage',
}

type FetchVariables = {
  url: string
  breadcrumbUrls: string[]
  locale: string
  fallbackLocale: boolean
}

interface CMSPageI {
  fetch(): void
}

export abstract class CMSPage implements CMSPageI {
  ctx: Context
  options: {
    fetchVariables: FetchVariables
  }

  pageData?: unknown
  breadcrumbs: BreadcrumbsData = []
  title: string = ''
  componentName: CMSPageComponentTypes = CMSPageComponentTypes.ContentPage

  constructor(ctx: Context, fetchVariables: FetchVariables) {
    this.ctx = ctx
    this.options = {
      fetchVariables,
    }
    this.pageData = null
  }

  fetch() {
    throw new Error('Method not implemented.')
  }
}

type ProtocolData = Protocol & { protocolMarkup: any }

class CMSProtocolPage extends CMSPage {
  pageData?: ProtocolData | ContentPage
  componentName = CMSPageComponentTypes.ProtocolPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchProtocolPageByUrl(this.options.fetchVariables)

    if (response.data.all_protocol?.items?.length) {
      const regex = /<h2 class="protocol-title(.*?)<\/h2>/g
      const protocolData = response.data.all_protocol?.items?.at(0)
      let protocolMarkup = protocolData?.html_content
      if (protocolData?.number) {
        protocolMarkup = await this.fetchProtocolMarkup(protocolData)
      }
      const protocolMarkupAdjusted = protocolMarkup ? protocolMarkup.replace(regex, '') : null

      this.pageData = {
        protocolMarkup: protocolMarkupAdjusted,
        ...protocolData,
      }
    } else {
      this.pageData = response.data.all_content_page?.items?.at(0) as ContentPage | undefined
      this.componentName = CMSPageComponentTypes.ContentPage
    }
    this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }

  // Method to fetch the markup from protocol JSON
  async fetchProtocolMarkup(protocolData: any) {
    const protocolUrl = `${this.ctx.$config.cst.mediaUrl}/json2/protocol/${protocolData.number}`
    try {
      const { data } = await axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        url: protocolUrl,
      })

      return data['step-protocol'].markup ?? null
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

class CMSProteinDomainPage extends CMSPage {
  pageData?: ProteinDomain | ContentPage
  componentName = CMSPageComponentTypes.ProteinDomainPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchProteinDomainPageByUrl(this.options.fetchVariables)

    if (response.data.all_protein_domain?.items?.length) {
      this.pageData = response.data.all_protein_domain?.items?.at(0) as ProteinDomain | undefined
    } else {
      this.pageData = response.data.all_content_page?.items?.[0] as ContentPage | undefined
      this.componentName = CMSPageComponentTypes.ContentPage
    }
    this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }
}

class CMSPathwayPage extends CMSPage {
  pageData?: PathwayDiagram | ContentPage
  componentName = CMSPageComponentTypes.PathwayDiagramPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchPathwaysPageByUrl(this.options.fetchVariables)

    if (response.data.all_pathway_diagram?.items?.length) {
      this.pageData = response.data.all_pathway_diagram?.items?.at(0) as PathwayDiagram | undefined
    } else {
      this.pageData = response.data.all_content_page?.items?.[0] as ContentPage | undefined
      this.componentName = CMSPageComponentTypes.ContentPage
    }
    this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }
}

class CMSVideoPage extends CMSPage {
  pageData?: ContentVideo | ContentPage
  componentName = CMSPageComponentTypes.VideoPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchContentVideoPageByUrl(this.options.fetchVariables)

    if (response.data.all_content_video?.items?.length) {
      this.pageData = response.data.all_content_video?.items?.at(0) as ContentVideo | undefined
    } else {
      this.pageData = response.data.all_content_page?.items?.[0] as ContentPage | undefined
      this.componentName = CMSPageComponentTypes.ContentPage
    }
    this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }
}

class CMSNewsPage extends CMSPage {
  pageData?: News
  componentName = CMSPageComponentTypes.NewsPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchNewsPageByUrl(this.options.fetchVariables)

    this.pageData = response.data.all_news?.items?.at(0) as News | undefined
    // this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }
}

class CMSContentPage extends CMSPage {
  pageData?: ContentPage
  componentName = CMSPageComponentTypes.ContentPage

  async fetch() {
    const response = await this.ctx.$cms.api.fetchContentPageByUrl(this.options.fetchVariables)

    this.pageData = response.data.all_content_page?.items?.at(0) as ContentPage | undefined
    this.breadcrumbs = generateBreadcrumbsData((response.data.breadcrumbs?.items ?? []) as NavigationLink[])
    this.title = this.pageData?.page?.title || ''
  }
}

abstract class CMSContentPageBuilder {
  static build(ctx: Context, fetchVariables: FetchVariables) {
    let page
    if (ctx.route.path.startsWith('/news')) {
      page = new CMSNewsPage(ctx, fetchVariables)
    } else if (ctx.route.path.startsWith('/learn-and-support/protocols')) {
      page = new CMSProtocolPage(ctx, fetchVariables)
    } else if (ctx.route.path.startsWith('/learn-and-support/protein-domains-and-interactions')) {
      page = new CMSProteinDomainPage(ctx, fetchVariables)
    } else if (ctx.route.path.startsWith('/pathways')) {
      page = new CMSPathwayPage(ctx, fetchVariables)
    } else if (ctx.route.path.startsWith('/learn-and-support/videos-and-webinars')) {
      page = new CMSVideoPage(ctx, fetchVariables)
    } else {
      page = new CMSContentPage(ctx, fetchVariables)
    }

    return page
  }
}

export function useCMSPage(ctx: Context) {
  const userStore = useUserStore(ctx.$pinia)
  const url = withoutTrailingSlash(ctx.route.path)
  const breadcrumbUrls = generateBreadcrumbUrls(ctx.route.path)
  const locale = userStore.selectedLocale.toLowerCase()
  const fallbackLocale = true

  const page = CMSContentPageBuilder.build(ctx, { url, breadcrumbUrls, locale, fallbackLocale })

  return { page }
}

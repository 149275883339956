import { NavigationLink } from '~/modules/contentstack/types'
import { BreadcrumbsData } from '~/types/breadcrumbs'

export function generateBreadcrumbUrls(route: string): string[] {
  const parts = route.split('/')
  const breadcrumbUrls = parts.reduce(
    (acc: string[], _part, index) => {
      if (index !== 0) {
        acc.push(parts.slice(0, index + 1).join('/'))
      }
      return acc
    },
    ['/']
  )

  // console.log(breadcrumbUrls)
  return breadcrumbUrls
}

export function generateBreadcrumbsData(breadcrumbs: NavigationLink[]) {
  const breadcrumbsData: BreadcrumbsData = []

  breadcrumbs
    ?.sort((a, b) => {
      return a.link_v2?.href?.localeCompare(b.link_v2?.href || '') ?? 1
    })
    .forEach((link) => {
      if (link.link_v2?.href === '/') {
        return
      }
      breadcrumbsData.push({
        label: link.link_v2?.title ?? '',
        url: link.link_v2?.href ?? '',
      })
    })

  return breadcrumbsData
}

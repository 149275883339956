
import { defineComponent } from '@nuxtjs/composition-api'
import { H1_CRAWLER_FIELD_NAME } from '@/common/constants/cms'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  render(h) {
    return h({
      template: `<h1 data-elastic-name="${H1_CRAWLER_FIELD_NAME}">${this.title}</h1>`,
    })
  },
})
